import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import cdContent from "./components/content";
import CommonFooter1 from "@/components/common-footer1"; // import data from "./data";

import productData from "productData";
import { mapMutations } from "vuex"; // import TDK from "/public/data/TDK.js";

export default {
  // metaInfo: {
  //   title: "产品中心_常达智能", // set a title
  //   meta: [
  //     {
  //       // set meta
  //       name: "keyWords",
  //       content:
  //         "产品中心,常达,,电子标签,rfid电子标签,rfid智能柜,rfid档案管理,rfid档案柜,rfid工具,rfid工具柜,rfid医疗柜",
  //     },
  //     {
  //       name: "description",
  //       content: "产品中心",
  //     },
  //   ],
  // },
  components: {
    CommonFooter1: CommonFooter1,
    cdContent: cdContent
  },
  computed: {
    name: function name() {
      return this.$route.query.name;
    },
    listFlat: function listFlat() {
      var arr = [];
      this.list.forEach(function (item) {
        item.children.forEach(function (c2) {
          if (c2.children) arr.push.apply(arr, _toConsumableArray(c2.children));else arr.push(c2);
        });
      });
      return arr;
    },
    data: function data() {
      var _this = this;

      // console.log(this.listFlat);
      return this.listFlat.find(function (item) {
        return item.name === _this.name;
      });
    },
    urlCurrent: function urlCurrent() {
      return this.$route.query.current || false;
    }
  },
  watch: {
    name: {
      immediate: true,
      handler: function handler(name) {
        if (!name) {
          this.$router.push({
            query: {
              name: "NFC标签"
            }
          });
        } else {
          this.parseName = name;
        }
      }
    },
    "$route.query.c": function $routeQueryC(c) {
      c && (this.contentCurrent = c);
    },
    parseName: {
      immediate: true,
      handler: function handler(name) {
        var _this2 = this;

        var headerName = "";

        function set(hName, item) {
          headerName = hName;
          var index = item.data && item.data.findIndex(function (item) {
            return item.parseName === name;
          });
          if (item.children) index = -1;
          this.contentCurrent = index === -1 ? 0 : index;
        }

        !this.isUp && this.list.forEach(function (listItem) {
          var hName = listItem.headerName;

          if (_this2.urlCurrent) {
            _this2.contentCurrent = ~~_this2.urlCurrent;
          } else {
            listItem.children.forEach(function (item) {
              if (item.children) {
                item.children.forEach(function (item) {
                  if (item.name === name) {
                    set.call(_this2, hName, item);
                  }
                });
              } else if (item.name === name) {
                set.call(_this2, hName, item);
              }
            });
          }
        }); // console.log(headerName);

        this.upUrlHeaderName(headerName);
      }
    },
    contentCurrent: function contentCurrent() {
      this.isUp = false;
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations(["setProductName", "setHeaderColorBlack", "setHeaderColorWhite"])), {}, {
    showChild2: function showChild2(cItem) {
      cItem.show = !cItem.show;
    },
    upUrlHeaderName: function upUrlHeaderName(headerName) {
      this.setProductName(headerName);
    },
    up: function up($event) {
      this.parseName = $event.parseName;
      this.$router.push({
        query: {
          name: $event.parseName,
          current: $event.index
        }
      });
      this.contentCurrent = $event.index;
      this.isUp = true;
    },
    getSeoUrl: function getSeoUrl() {
      var urls = [];
      this.list.forEach(function (item) {
        item.children.forEach(function (c) {
          c.data.forEach(function (d, i) {
            var h = "<url>\n                <loc>https://www.cirteklink.com/product?name=".concat(c.name, "&current=").concat(i, "</loc>\n                <changefreq>daily</changefreq>\n                <priority>0.8</priority>\n              </url>");
            urls.push(h);
          });
        });
      });
    }
  }),
  data: function data() {
    return {
      isUp: false,
      contentCurrent: 0,
      parseName: "NFC标签",
      list: productData
    };
  },
  mounted: function mounted() {
    // TDK.setTDK("product");
    this.setHeaderColorWhite(); // this.getSeoUrl();

    setTimeout(function () {}, 3000);
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};